var React = require('react');
const {useEffect, useState} = require("react");
const {useTranslation, withTranslation} = require("react-i18next");
const NotificationsList = require("./NotificationsList")
const {connect, useSelector} = require("react-redux");
var notificationsManager = require('notificationsManager');
var userActions = require('userActions');
var {upper} = require('helpers');
const ComplexSelect = require('ComplexSelect');

const NotificationsCenterContainer = (props) => {

    const {t} = useTranslation();
    const selectAllObject = {key: "all", title: upper(t('all')), isSelected: true};
    const [allNotificationsData, setAllNotificationsData] = useState([]);
    const [selectedFieldNotificationsData, setSelectedFieldNotificationsData] = useState([])
    const [shouldDisplayWelcomeNotification, setShouldDisplayWelcomeNotification] = useState(false);
    const [distributionsOptions, setDistributionsOptions] = useState([]);
    const [selectedDistribution, setSelectedDistribution] = useState({});
    const [selectedFarmingUnit, setSelectedFarmingUnit] = useState({});
    const [selectedField, setSelectedField] = useState({});
    const [farmingUnitsOptions, setFarmingUnitsOptions] = useState([]);
    const [fieldsOptions, setFieldsOptions] = useState([]);
    const [filteredNotifications, setFilteredNotifications] = useState([]);
    const [enableSelection, setEnableSelection] = useState({distribution: false, farmingUnit: false, field: false});
    const all_distributions = useSelector((state) => state.distribution_data.distributions);

    useEffect(() => {
        if (all_distributions !== null) {
            const [distributionsArr, farmingUnitsArr, fieldsArr] = populateLists();
            setDistributionsOptions(distributionsArr);
            setFarmingUnitsOptions(farmingUnitsArr);
            setFieldsOptions(fieldsArr);
            setSelectedDistribution(distributionsArr[0]);
            setSelectedFarmingUnit(farmingUnitsArr[0]);
            setSelectedField(fieldsArr[0]);
        }
    }, [all_distributions])

    useEffect(() => {

        if (props?.selectedField !== null && Object.keys(props.notificationsData).length > 0) {
            let NotificationsDataRes = notificationsManager.notificationsDataMap(props.notificationsData, props?.selectedField?.id);
            setAllNotificationsData(NotificationsDataRes?.allNotifications);
            setFilteredNotifications(NotificationsDataRes?.allNotifications);
            setSelectedFieldNotificationsData(NotificationsDataRes?.currentFieldNotifications)

            if (Object.keys(props?.userData)?.length !== 0 && props.userData.hasOwnProperty("meta_data") && props.userData?.meta_data?.notifications?.welcome_notification) {
                setShouldDisplayWelcomeNotification(true)
            } else {
                setShouldDisplayWelcomeNotification(false)
            }
        }
    }, [props?.selectedField, props.notificationsData])

    const onChangeDistribution = (newSelectedDistribution) => {

        let [farmingUnitsArr, fieldsArr] = [];
        let finalSelectedFieldState = {};
        let finalSelectedFarmingUnitState = {};
        let enableEntitiesSelection = {};

        if (newSelectedDistribution.key !== "all") {
            [[], farmingUnitsArr, fieldsArr] = populateLists(newSelectedDistribution);

            if (Object.keys(farmingUnitsArr).length > 1) {
                finalSelectedFarmingUnitState = selectAllObject;
                finalSelectedFieldState = selectAllObject;
                enableEntitiesSelection = {farmingUnits: true, fields: false};
            } else {
                finalSelectedFarmingUnitState = farmingUnitsArr[0];

                if (Object.keys(fieldsArr).length > 1) {
                    finalSelectedFieldState = selectAllObject;
                    enableEntitiesSelection = {farmingUnits: false, fields: true};

                } else {
                    finalSelectedFieldState = fieldsArr[0];
                    enableEntitiesSelection = {farmingUnits: false, fields: false}
                }
            }
        } else {
            finalSelectedFarmingUnitState = selectAllObject;
            finalSelectedFieldState = selectAllObject
            enableEntitiesSelection = {farmingUnits: false, fields: false}
        }
        setSelectedDistribution(newSelectedDistribution);
        setSelectedFarmingUnit(finalSelectedFarmingUnitState);
        setSelectedField(finalSelectedFieldState);
        setFarmingUnitsOptions(farmingUnitsArr);
        setFieldsOptions(fieldsArr);
        setDistributionsOptions(prevState => {
            return prevState.map((distribution) => {
                if (distribution.key === newSelectedDistribution.key) {
                    distribution.isSelected = true;
                } else {
                    distribution.isSelected = false;
                }
                return distribution;
            })
        });
        setEnableSelection({
            ...enableSelection,
            farmingUnit: enableEntitiesSelection.farmingUnits,
            field: enableEntitiesSelection.fields
        });
        filterNotifications(newSelectedDistribution, finalSelectedFarmingUnitState, finalSelectedFieldState);
    }

    const onChangeFarmingUnit = (newSelectedFarmingUnit) => {
        let finalSelectedFieldState = {};
        let finalSelectedFarmingUnitState = {};
        let enableEntitiesSelection = {};
        let fieldsArr = [];

        if (newSelectedFarmingUnit.key !== "all") {
            [[], [], fieldsArr] = populateLists(undefined, newSelectedFarmingUnit);

            if (Object.keys(fieldsArr).length > 1) {
                finalSelectedFieldState = selectAllObject;
                finalSelectedFarmingUnitState = newSelectedFarmingUnit;
                enableEntitiesSelection = {field: true}

            } else {
                finalSelectedFieldState = fieldsArr[0];
                finalSelectedFarmingUnitState = selectAllObject;
                enableEntitiesSelection = {field: false}
            }
        } else {
            finalSelectedFieldState = selectAllObject;
            finalSelectedFarmingUnitState = selectAllObject;
            enableEntitiesSelection = {field: false}
        }
        setSelectedFarmingUnit(newSelectedFarmingUnit);
        setSelectedField(finalSelectedFieldState);
        setFieldsOptions(fieldsArr);
        setEnableSelection({...enableSelection, field: enableEntitiesSelection.field});
        setFarmingUnitsOptions(prevState => {
            return prevState.map((farmingUnit) => {
                if (farmingUnit.key === newSelectedFarmingUnit.key) {
                    farmingUnit.isSelected = true;
                } else {
                    farmingUnit.isSelected = false;
                }
                return farmingUnit;
            })
        });
        filterNotifications(selectedDistribution, finalSelectedFarmingUnitState, finalSelectedFieldState)
    }

    const onChangeField = (newSelectedField) => {
        setSelectedField(newSelectedField);
        setFieldsOptions(prevState => {
            return prevState.map((field) => {
                if (field.key === newSelectedField.key) {
                    field.isSelected = true;
                } else {
                    field.isSelected = false;
                }
                return field;
            })
        });
        filterNotifications(selectedDistribution, selectedFarmingUnit, newSelectedField)
    }

    const filterNotifications = (distribution, farmingUnit, field) => {

        if (distribution.key === "all") {
            setFilteredNotifications(allNotificationsData);
        } else {
            setFilteredNotifications(() => {
                let filteredNotifications = allNotificationsData.filter((notification) => {
                    if ((notification.distribution_id === distribution?.key && notification.farming_unit_id === farmingUnit?.key && notification.field_id === field?.key) ||
                        (notification.distribution_id === distribution?.key && farmingUnit?.key === "all" && field.key === "all") ||
                        (notification.distribution_id === distribution?.key && notification.farming_unit_id === farmingUnit?.key && field.key === "all")) {
                        return notification;
                    }
                });
                return filteredNotifications;
            })
        }
    }

    const updateUserWelcomeNotification = () => {

        setShouldDisplayWelcomeNotification(false);
        let copyOfUserData = {};
        Object.assign(copyOfUserData, props.userData);

        copyOfUserData["meta_data"] = {
            "notifications": {
                "welcome_notification": false
            }
        }
        props.setUserMetaData({"meta_data_notifications_welcome": copyOfUserData["meta_data"]["notifications"]["welcome_notification"]});
        mixpanel.track("Notification Welcome Message Dismissed");
    }

    const populateLists = (distribution = undefined, farmingUnit = undefined) => {
        let distributionsArr = [];
        let farmingUnitsArr = [];
        let returnedDistributionsList = [];
        let returnedFarmingUnitsList = [];
        let returnedFieldsList = [];

        if (distribution === undefined && farmingUnit === undefined) {
            distributionsArr = Object.values(all_distributions?.distributions)?.map((distribution) => {
                return {
                    farming_units: distribution.farming_units,
                    key: distribution.id,
                    title: distribution.name,
                    isSelected: false
                }
            }).sort((a, b) => a.title.localeCompare(b.title));
            farmingUnitsArr = distributionsArr.flatMap(distribution =>
                Object.values(distribution?.farming_units) || []
            ).sort((a, b) => a.name.localeCompare(b.name));


        } else if (distribution !== undefined && farmingUnit === undefined) {
            farmingUnitsArr = Object.values(distribution?.farming_units).sort((a, b) => a.name.localeCompare(b.name));

        } else if (distribution === undefined && farmingUnit !== undefined) {
            farmingUnitsArr = [farmingUnit];
        }
        returnedDistributionsList = distributionsArr;
        returnedFarmingUnitsList = farmingUnitsArr.filter((farmingUnit) => {
            return Object.keys(Object.values(farmingUnit?.fields).filter((field) => {
                return field?.settings?.field_state === "active"
            }))?.length > 0
        }).map((farmingUnit) => {
            return {
                fields: farmingUnit.fields,
                key: farmingUnit.id,
                title: farmingUnit.name,
                isSelected: false
            }
        }).sort((a, b) => a.title.localeCompare(b.title));
        returnedFieldsList = returnedFarmingUnitsList?.flatMap(farmingUnit =>
            Object.values(farmingUnit?.fields).filter(field => field.settings.field_state === "active")).map((field) => {
            return {
                key: field.id,
                title: field.name,
                isSelected: false
            }
        }).sort((a, b) => a.title.localeCompare(b.title));
        if (returnedDistributionsList?.length > 1 && returnedFarmingUnitsList?.length > 1) {
            setEnableSelection({
                distribution: true,
                farmingUnit: false,
                field: false
            })
        } else if (returnedFarmingUnitsList?.length === 1) {
            setEnableSelection({
                ...enableSelection,
                farmingUnit: false,
                field: true
            })
        }
        if (returnedDistributionsList?.length > 1) {
            returnedDistributionsList?.unshift({
                key: "all",
                title: upper(t('all')),
                isSelected: true,
            })
        }
        if (returnedFarmingUnitsList?.length > 1) {
            returnedFarmingUnitsList?.unshift({
                key: "all",
                title: upper(t('all')),
                isSelected: true,
            });
        }
        if (returnedFieldsList?.length > 1) {
            returnedFieldsList?.unshift({
                key: "all",
                title: upper(t('all')),
                isSelected: true,
            });
        }
        return [returnedDistributionsList, returnedFarmingUnitsList, returnedFieldsList]
    }

    return (
        <div>
            <div style={{
                borderBottom: "1px solid #DCE0E8",
                borderTop: "1px solid #DCE0E8",
                flexDirection: "row",
                textAlign: "center",
                alignItems: "center",
                position: "sticky",
                top: 0,
                backgroundColor: "white"
            }}>
                {distributionsOptions?.length > 1 && <ComplexSelect isDisable={!enableSelection.distribution} menuOptions={distributionsOptions}
                                selectedItem={selectedDistribution}
                                onItemClick={onChangeDistribution}
                                label={t("distribution")}/>}
                {(farmingUnitsOptions?.length > 1 || distributionsOptions?.length > 1)  && <ComplexSelect isDisable={!enableSelection.farmingUnit} menuOptions={farmingUnitsOptions}
                                selectedItem={selectedFarmingUnit}
                                onItemClick={onChangeFarmingUnit}
                                label={t("farming_unit")}/>}
                <ComplexSelect isDisable={!enableSelection.field} menuOptions={fieldsOptions} selectedItem={selectedField} onItemClick={onChangeField}
                                label={t("field")}/>
            </div>
            <NotificationsList onCloseClicked={props.onCloseClicked} shouldDisplayWelcomeNotification={shouldDisplayWelcomeNotification}
                               updateUserWelcomeNotification={updateUserWelcomeNotification}
                               notificationsData={filteredNotifications}/>
        </div>
    );
}

const mapStateToProps = function (state) {

    return {
        selectedField: state.distribution_data.selected_entities.field,
        active_distribution: state.distribution_data.selected_entities.distribution,
        userData: state.authentication_data.user_data,

    }
}

const mapDispatchToProps = {
    setUserMetaData: userActions.set_user_meta_data,
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NotificationsCenterContainer));