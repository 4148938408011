const React = require("react");
const {connect} = require("react-redux");
const {withTranslation} = require("react-i18next");

const ComplexSelect = ({menuOptions, selectedItem, onItemClick, label, isDisable}) => {

    return (
        <div style={{
            marginRight: "16px",
            marginLeft: "16px",
            marginTop: "8px",
            marginBottom: "8px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
        }}>
            <div className="text-size-16 text-grey71" style={{width: "180px", textAlign: "start"}}>{label}</div>
            <div tabindex={isDisable ? undefined : "0"} className={isDisable ? "disable-selector" : "custom-select"}
                 style={{height: "24px", paddingRight: "0px", textAlign: "start", fontSize: "14px"}}>
                <div className="d-flex align-items-center" id="dropdownMenuButton"
                     data-toggle={isDisable ? "none" : "dropdown"} aria-haspopup="true" aria-expanded="false"
                     style={{bottom: "5px", position: "relative", cursor: "pointer"}}>
                    <div className="overflow-hidden text-truncate">
                        <div className="font-weight-normal" style={{
                            color: isDisable ? "#999CA3" : "#4D5674",
                            width: "160px",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden"
                        }}>{selectedItem?.title}</div>
                    </div>
                </div>
                <div className="dropdown-menu dropdown-menu-right scrollable-menu"
                     aria-labelledby="dropdownMenuButton">
                    {menuOptions?.map((option, index) => {

                        return (<div className="dropdown-item text-size-16 text-grey45" style={{
                            display: "flex",
                            flexDirection: "row",
                            borderRadius: index === 0 ? "0px" : "3px",
                            paddingLeft: "8px",
                            borderBottom: index === 0 ? "1px solid #DCE0E8" : "none",
                            paddingTop: index === 0 ? "0px" : "8px"
                        }} onClick={() => {
                            onItemClick(menuOptions[index])
                        }}>
                            {option?.isSelected ?
                                <img style={{marginRight: "8px"}} src={require('images/icons/v_icon.svg')}/> :
                                <div style={{width: "18px"}}/>}
                            <div className="text-grey45 text-size-16" key={option?.title}>{option?.title}</div>
                        </div>)
                    })}
                </div>
            </div>
        </div>
    )
}

module.exports = connect()(withTranslation()(ComplexSelect));